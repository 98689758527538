
    import { defineComponent, reactive, toRefs } from 'vue';
    import useTableHeight from "@/hooks/useTableHeight";
    import useFetchCommon from "@/hooks/useFetchCommon";
    import { getDealerList } from "@/API/dealerMaster";
    import Pagination from "@/components/Pagination.vue";
    import { DealerListProps, DealerListQueryParams } from './types';
    import { useI18n } from 'vue-i18n';

    export default defineComponent({
        name: "Dealer",
        components: {
            Pagination
        },
        setup() {
            const { t } = useI18n()

            const columns = [
                {
                    title: t('dealerData.status'),
                    dataIndex: 'statusName',
                    key: 'statusName',
                    width: 100
                },
                {
                    title: t('dealerData.gsCode'),
                    dataIndex: 'gsCode',
                    key: 'gsCode',
                    width: 160
                },
                {
                    title: t('dealerData.dealerCode'),
                    dataIndex: 'dealerCode',
                    key: 'dealerCode',
                    width: 160
                },
                {
                    title: t('dealerData.dealerNameCn'),
                    dataIndex: 'nameCn',
                    key: 'nameCn',
                    width: 260
                },
                {
                    title: t('dealerData.dealerNameEn'),
                    dataIndex: 'nameEn',
                    key: 'nameEn',
                    width: 260,
                },
                {
                    title: t('dealerData.dealerGroup'),
                    dataIndex: 'dealerGroupNameCn',
                    key: 'dealerGroupNameCn',
                    width: 150
                },
                {
                    title: t('dealerData.provinceEn'),
                    dataIndex: 'provinceEn',
                    key: 'provinceEn',
                    width: 150
                },
                {
                    title: t('dealerData.cityEn'),
                    dataIndex: 'cityEn',
                    key: 'cityEn',
                    width: 150
                },
                {
                    title: t('dealerData.operation'),
                    dataIndex: 'operation',
                    key: 'operation',
                    slots: { customRender: 'operation' },
                    width: 150
                }
            ];
            const { tableHeight } = useTableHeight();

            const dealerListQueryParams = reactive<DealerListQueryParams>({
                dealerCode: '',
                dealerNameEn: '',
                dealerNameCn: ''
            });

            const { data, pagination, refreshFetch } = useFetchCommon<DealerListProps[]>({ params: dealerListQueryParams, initData: [], fetchApi: getDealerList, isPagination: true })

            const handlerReset = () => {
                Object.assign(dealerListQueryParams, { dealerCode: '', dealerNameEn: '', dealerNameCn: '' });
                pagination.page = 1;
                refreshFetch();
            }

            const handlerSearch = () => {
                pagination.page = 1;
                refreshFetch();
            }

            //分页事件处理
            const handlerPaginationChange = (page: number): void => {
                pagination.page = page;
                refreshFetch(false);
            }

            const handlerShowSizeChange = (current: number, pageSize: number): void => {
                pagination.size = pageSize;
                Object.assign(pagination, { page: current, size: pageSize });
                refreshFetch(false);
            }

            return {
                tableHeight,
                columns,
                data,
                pagination,
                refreshFetch,
                ...toRefs(dealerListQueryParams),

                handlerReset,
                handlerSearch,
                handlerPaginationChange,
                handlerShowSizeChange
            }
        }
    })
